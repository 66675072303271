// src/googleOAuth.js

// Import environment variables
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const SCOPES = [
  'https://www.googleapis.com/auth/youtube.readonly',
  'https://www.googleapis.com/auth/youtube.force-ssl'
].join(' ');


//testing branches right now
export { CLIENT_ID, SCOPES }; 