import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, getDoc, setDoc, arrayUnion, arrayRemove, onSnapshot, connectFirestoreEmulator, updateDoc, deleteField } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// Firebase configuration details
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Firebase Functions (Callable functions)
const functions = getFunctions(app);

// Initialize Firebase Authentication and Google Auth Provider
const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

// Point to the emulator if in development environment
if (window.location.hostname === "localhost" || process.env.APP_ENV === 'local') {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectStorageEmulator(storage, "localhost", 9199); // Add this line
  connectFunctionsEmulator(functions, "localhost", 5001);
}


// Export the Firebase services for use in other parts of your app
export { auth, googleProvider, db, doc, getDoc, setDoc, arrayUnion, arrayRemove, onSnapshot, updateDoc, deleteField, storage};
