import React, { useState, useEffect, useContext } from "react";
import './HomePage.css';
import abovefoldImage from '../images/previewStamp-v1.jpg';
import keyMommentsImage from '../images/keypointsDesign.jpg';
import utubeLogoon from '../images/v2-youtubeon.png';
import openAiLogo from '../images/openai.png';
import bumpupsLogo from '../images/bumpups-dark-small.jpg';
import giftLogo from '../images/giftlogo-v3.png';
import { AuthProvider } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import CookieBar from '../miscPages/CookieBar';
import SellingPoints from '../miscPages/SellingPoints';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../AuthContext';


function HomePage() {
    // State to manage the visibility of the modal
    const [faqOpen, setFaqOpen] = useState(null);  // New state for FAQ accordion

    const navigate = useNavigate();

    // Function to handle the button click
    const handleButtonClick = () => {
        navigate('/signup');
    };

    const handleHelpCenter = () => {
        window.open('https://intercom.help/tubestamp/en/', '_blank');
    };
    
    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        // console.log("Current user: ", currentUser);
        if (currentUser) {
          navigate('/dashboard');
        }
      }, [currentUser, navigate]);

    // Function to toggle FAQ accordion
    const toggleFaq = (index) => {
        setFaqOpen(faqOpen === index ? null : index);
    };

    return (
        <AuthProvider>
        <Helmet>
            <title>Tubestamp - AI-powered YouTube Timestamps</title>
            <meta name="description" content="Automate your YouTube video navigation with Tubestamp. Our AI-powered platform generates timestamps and posts them as the first comment, enhancing viewer experience." />
        </Helmet>

                            <div className="homepage-container">
                        <div className="homepage-text">
                            <img src={bumpupsLogo} alt="Bumpups" className="bumpups-logo"/>
                            <h2 className="top-line">PERFECT FOR CONTENT CREATORS</h2>
                            <h1>1 video upload,<br />
                                10 key moments,<br />
                                10+ SEO results.</h1>
                                <div className="powered-by-container">
                                <p className="powered-by-text">
                                Tubestamp will maximize your video's potential on any platform 
                                <span className="emphasis"> in one click</span>. Powered by 
                                <img src={openAiLogo} alt="Powered by OpenAI" className="openai-logo"/>
                            </p>

                            </div>
                            <button className="CTA-button-home" onClick={handleButtonClick}>
                        Get free 60 minutes <img src={giftLogo} alt="Icon" className="button-icon"/>
                        </button>
                        </div>
                        <div className="homepage-image">
                            <img src={abovefoldImage} alt="Timestamp AI Logo" />
                        </div>
                    </div>

                    <div className="valueExamples">
                            <div className="valueExamples-text">
                                <div className="youtube-logo-container">
                                <img src={utubeLogoon} alt="YouTube Logo" className="youtube-logohomepage" />

                                <h2 className="seamless-integration-title">Made For Creators</h2>
                                </div>
                           </div>
                            <div className="valueExamples-image">
                                <img src={keyMommentsImage} alt="Key Moments Design" />
                            </div>
                            </div>


            <SellingPoints />
            <div className="faq-section">
                <h1>Got questions?</h1>
                <button className="helpcenter-button" onClick={handleHelpCenter}>
                    Go to help center
                </button>
                <div className="faq-item" onClick={() => toggleFaq(6)}>
                    <h3>Is Tubestamp free to use?</h3>
                    <div className={faqOpen === 6 ? "faq-content show" : "faq-content"}>
                        Yes, Tubestamp offers 60 minutes of free timestamp generation, allowing you to experience the value of our service without any initial investment. After the free trial, our competitive pricing plans offer great value for the features provided.
                    </div>
                </div>

                <div className="faq-item" onClick={() => toggleFaq(5)}>
                    <h3>Which languages are supported?</h3>
                    <div className={faqOpen === 5 ? "faq-content show" : "faq-content"}>
                    We currently only support English, but we are working on expanding to all languages very soon. This will make Tubestamp a versatile tool for content creators around the globe.
                    </div>
                </div>

                <div className="faq-item" onClick={() => toggleFaq(0)}>
                    <h3>How does the AI generate timestamps?</h3>
                    <div className={faqOpen === 0 ? "faq-content show" : "faq-content"}>
                        Our advanced AI algorithms analyze your video content to identify key chapters, enabling viewers to navigate your content more efficiently. This feature enhances viewer engagement and increases the likelihood of longer watch times.
                    </div>
                </div>
    
                <div className="faq-item" onClick={() => toggleFaq(1)}>
                    <h3>Is it possible to edit the timestamps after they are generated?</h3>
                    <div className={faqOpen === 1 ? "faq-content show" : "faq-content"}>
                        Absolutely. Once the timestamps are automatically posted as a comment on your video, you have the full flexibility to edit them as you see fit. This gives you complete control over how your content is presented to your audience.
                    </div>
                </div>
    
                <div className="faq-item" onClick={() => toggleFaq(2)}>
                    <h3>Is there a limit to the number of videos I can generate timestamps for?</h3>
                    <div className={faqOpen === 2 ? "faq-content show" : "faq-content"}>
                        We offer timestamp generation for the first 60 minutes of video content at no cost. Beyond that, charges will apply based on the total duration of the videos. Our pricing plans are designed to be flexible and affordable, catering to creators of all sizes.
                    </div>
                </div>
    
                <div className="faq-item" onClick={() => toggleFaq(3)}>
                    <h3>What video durations can the platform support?</h3>
                    <div className={faqOpen === 3 ? "faq-content show" : "faq-content"}>
                        At present, our platform supports videos ranging from <b>4 minutes to  3.5 hours</b> in length. We are actively working on extending this range to accommodate longer videos. Stay tuned for updates as we continually improve our service.
                    </div>
                </div>
    
    
            </div>
            <CookieBar /> 
            <Footer />
        </AuthProvider>
    );
    
}

export default HomePage;
