import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import './Navbar.css';

function Navbar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { currentUser } = useContext(AuthContext);

  const bumpupsRedirect = () => {
    window.location.href = 'https://bumpups.com/';
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="brand-and-toggle-container">
        <Link to={currentUser ? "/dashboard" : "/"} className="navbar-brand">
          <span className="tubebrandnav-tube">tube</span>
          <span className="tubebrandnav-stamp">stamp</span>
        </Link>
      </div>
      <div className="navbar-links">
        <button className="nav-button signup" onClick={bumpupsRedirect}>
          Go to bump<span className="highlight-ups">ups</span>.com
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
