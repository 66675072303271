import React, { useState, useEffect } from "react";
import './Footer.css';
import { Link } from 'react-router-dom';
import cafeLogo from '../images/corplogo-dark.png';
import twitLogo from '../images/twit-white.png';


const Footer = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
    
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    


    const scrollToTopAndNavigate = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        // Add your navigation logic here
    };
    

    return (
        <footer className="footer-container">
            
            <div className="footer-bottom" style={isMobile ? { textAlign: 'center', paddingLeft: '0px' } : {}}>
                ©2024 Bumpups - All rights reserved.
                <Link to="https://bumpups.com/terms-of-service" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => scrollToTopAndNavigate('/terms-of-service')}>
                    Terms of Service
                </Link>
                <Link to="https://bumpups.com/privacy-policy" className="contact-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => scrollToTopAndNavigate('/privacy-policy')}>
                    Privacy Policy
                </Link>
            </div>
        </footer>
    );

    
    
}

export default Footer;
