import { useEffect, useState } from 'react';
import './PlanPopUp.css';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from "firebase/functions";



import bumpupsLogo from '../images/bumpup-small.png';


const formatTime = (minutes) => {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  return `${h}h ${m}m`;
};


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);


const PlanPopUp = ({ isOpen, onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState('Essential');
  const [selectedMinutes, setSelectedMinutes] = useState(100);
  const [timeBank, setTimeBank] = useState(null);

  const [stripeButtonText, setStripeButtonText] = useState("Proceed to Stripe");

  const handleMinutesChange = async (e) => {
    const selectedValue = e.target.value;
  
    setSelectedMinutes(selectedValue);
    // console.log("Selected Minutes:", selectedValue);

  };



useEffect(() => {
  const user = auth.currentUser;
  if (user) {
    const subscriptionDocRef = doc(db, 'users', user.uid, 'subscriptionData', 'timeWarden');

    const fetchTimeData = async () => {
      const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
      if (sessionTimeBank !== null) {
        // console.log("Loading timeBank from sessionStorage for user:", user.uid);
        setTimeBank(parseInt(sessionTimeBank, 10));  // Parse as integer in case it's stored as a string
      } else {
        // console.log("Fetching timeBank from Firestore for user:", user.uid);
        const docSnap = await getDoc(subscriptionDocRef);
        if (docSnap.exists()) {
          const timeBank = docSnap.data().timeBank || 0;
          setTimeBank(timeBank);
          sessionStorage.setItem(`timeBank_${user.uid}`, timeBank.toString());
        } else {
          // console.log("No timeBank data found in Firestore for user:", user.uid);
        }
      }
    };

    // Call fetchTimeData to load timeBank initially
    fetchTimeData();

    const unsubscribe = onSnapshot(subscriptionDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const newTimeBank = docSnap.data().timeBank || 0;
        const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
        
        // Only update if the newTimeBank is different from what's stored in sessionStorage
        if (!sessionTimeBank || parseInt(sessionTimeBank, 10) !== newTimeBank) {
          // console.log("timeBank updated in Firestore, updating state and sessionStorage for user:", user.uid);
          setTimeBank(newTimeBank);
          sessionStorage.setItem(`timeBank_${user.uid}`, newTimeBank.toString());
        }
      }
    });

    // Cleanup function to unsubscribe from the real-time updates when the component unmounts
    return () => {
      unsubscribe();
      // console.log("Unsubscribed from timeBank updates for user:", user.uid);
    };
  }
}, []);  // Depend on auth.currentUser to re-run the effect if the currentUser changes



const startCheckoutSession = async () => {
  setStripeButtonText("Loading...");

  try {
      const functions = getFunctions(); // Get the Firebase functions instance
      const processCheckout = httpsCallable(functions, 'processCheckout'); // Create a reference to your callable function

      // Call the function directly, Firebase handles the request
      const response = await processCheckout({ selectedMinutes: selectedMinutes });

      const { id } = response.data; // Firebase functions pass data in the data property
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: id });

      setStripeButtonText("Proceed to Stripe");
  } catch (error) {
      console.error('Error during checkout:', error);
      setStripeButtonText("Retry"); // Reset button text on error
      // alert("Failed to initiate checkout. Please try again."); // Optional: user feedback on failure
  }
};

  
  

  if (!isOpen) return null;


  return (
    <>
       <div className="overlay" onClick={onClose}>
        <div className="plan-popup-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="plan-popup-container">
          <button className="close-btn" onClick={onClose}>X</button>
          <h2 className="popup-header">Running out of time?</h2>
          <div className="select-wrapper">
          <select 
          className="plan-choice-select" 
          onChange={(e) => setSelectedPlan(e.target.value)} 
          value={selectedPlan}
          disabled  // This disables the dropdown
        >
          <option value="Essential">Essential</option>
        </select>
          </div>
          {selectedPlan === 'Essential' && (
            <div className="essential-info">
                <p className="monthly-text">Monthly <img src={bumpupsLogo} alt="Bump Up Logo" className="bumpup-logo" /></p>
                <p className="price-text">${(selectedMinutes / 100 * 1.99).toFixed(2)} USD</p>
                <p className="upload-minutes-text">{selectedMinutes} upload minutes</p>
                <p className="expiry-info-text">Receive monthly upload time,<br /> expires in 60 days</p>
                <select className="minutes-select plan-select" onChange={handleMinutesChange} value={selectedMinutes}>
                  <option key={100} value={100} data-stripe-id="price_1Nvs2ZFjEvwreeYsWO7rYFtI">100</option>
                  <option key={200} value={200} data-stripe-id="price_1NvsHQFjEvwreeYshE2U4PUI">200</option>
                  <option key={400} value={400} data-stripe-id="price_1NwchtFjEvwreeYs66LGvHF1">400</option>
                  <option key={600} value={600} data-stripe-id="price_1NwcjEFjEvwreeYsBYlZoOXg">600</option>
                  <option key={800} value={800} data-stripe-id="price_1NwcjPFjEvwreeYsrBI8IUk5">800</option>
                  <option key={1000} value={1000} data-stripe-id="price_1NwcjZFjEvwreeYsyyGS35ON">1000</option>
              </select>
            </div>
          )}
          <div className="additional-info">
        <p className="rebuy-info">      Rebuy/Upgrade initiates a <strong>new plan at full cost</strong> and new upload time will be <strong>available instantly and expire in 60 days</strong>.</p>
        <p className="current-credits-info">Your current {timeBank ? formatTime(timeBank) : 'N/A'} of upload time will extend to the end of your new monthly billing period.</p>
        <p className="new-plan-info">      The new plan renews monthly at US${(selectedMinutes / 100 * 1.99).toFixed(2)} and your previous plan will be discontinued.</p>
        </div>
        {/* <button className="proceed-btn" onClick={startCheckoutSession}>{stripeButtonText}</button> */}
        {/* <button className="proceed-btn" >Proceed to Stripe</button> */}
        </div>
      </div>
      </div>
    </>
  );
  
};

export default PlanPopUp;
