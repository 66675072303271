import React, { createContext, useState, useEffect } from 'react';
import { auth } from './firebase'; // Import updateDoc


// Create the context
export const AuthContext = createContext();

// Create the provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(async user => {
      setCurrentUser(user);
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
