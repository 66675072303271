import React, { useState, useEffect, useContext } from "react";
import './tubestampLanding.css';
import abovefoldImage from '../images/bumpups-stamps.jpg';
import bumpupsLogo from '../images/bumpup-small.png';
import llamaTubeStamp from '../images/newuser.jpg';
import { AuthProvider } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Footer from '../views/Footer';
import CookieBar from '../miscPages/CookieBar';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../AuthContext';
import { auth } from '../firebase';




import { unregisterAllListeners } from '../components/ListenerManager';

function TubestampLanding() {
    const [faqOpen, setFaqOpen] = useState(null);
    const navigate = useNavigate();
    const { currentUser } = useContext(AuthContext);

    const handleButtonClick = () => {
        window.location.href = 'https://bumpups.com/';
    };

    const handleLogout = async () => {
        try {
            unregisterAllListeners();
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    useEffect(() => {
        if (currentUser) {
            handleLogout();
        }
    }, [currentUser]);

    useEffect(() => {
        // Check if the storage has been cleared
        if (!sessionStorage.getItem('storageCleared')) {
            // Clear local storage and session storage
            localStorage.clear();
            sessionStorage.clear();
            // Set the flag to prevent infinite reload
            sessionStorage.setItem('storageCleared', 'true');
            // Reload the page
            window.location.reload();
        }
    }, []);

    const toggleFaq = (index) => {
        setFaqOpen(faqOpen === index ? null : index);
    };

    return (
        <AuthProvider>
            <Helmet>
                <title>Tubestamp - AI-powered YouTube Timestamps</title>
                <meta name="description" content="Automate your YouTube video navigation with Tubestamp. Our AI-powered platform generates timestamps and posts them as the first comment, enhancing viewer experience." />
            </Helmet>

            <div className="landing-homepage-container">
                <div className="landing-homepage-text">
                    <img src={llamaTubeStamp} alt="Bumpups" className="landing-bumpups-logo"/>
                    <h2 className="landing-top-line">PERFECT FOR CONTENT CREATORS & EDITORS</h2>
                    <h1 className="announcement-title">We have moved<br/>to bump<span className="highlight-ups">ups</span>.com!</h1>
                    <div className="landing-powered-by-container">
                        <p className="landing-powered-by-text">
                            With just <span className="landing-emphasis">1 video upload</span>, 
                            you get <span className="landing-emphasis">10 key moments</span> and 
                            <span className="landing-emphasis"> 10+ SEO results</span>.
                        </p>
                    </div>
                    <button className="landing-CTA-button-home" onClick={handleButtonClick}>
                    Go to bump<span className="highlight-ups">ups</span>.com <img src={bumpupsLogo} alt="Icon" className="landing-button-icon"/>
                </button>
                </div>
                <div className="landing-homepage-image">
                    <img src={abovefoldImage} alt="Timestamp AI Logo" />
                </div>
            </div>

            <div className="landing-faq-section">
                <h1>Got questions?</h1>

                <div className="landing-faq-item" onClick={() => toggleFaq(0)}>
                    <h3>What does this mean if I already have an account with Tubestamp?</h3>
                    <div className={faqOpen === 0 ? "landing-faq-content show" : "landing-faq-content"}>
                        If you already have an account with Tubestamp, <b>your account will be seamlessly migrated to Bumpups.</b> You can use your existing credentials to log in at bumpups.com and enjoy all the features you had access to on Tubestamp, along with new enhancements.
                    </div>
                </div>

                <div className="landing-faq-item" onClick={() => toggleFaq(1)}>
                    <h3>Why did Tubestamp change to Bumpups?</h3>
                    <div className={faqOpen === 1 ? "landing-faq-content show" : "landing-faq-content"}>
                        Tubestamp rebranded to Bumpups to better reflect our expanded services and commitment to enhancing the content creation experience across multiple platforms. Our new brand <b>allows us to innovate and offer more features</b> to our users.
                    </div>
                </div>

                <div className="landing-faq-item" onClick={() => toggleFaq(2)}>
                    <h3>Will my previous timestamps and data be available on Bumpups?</h3>
                    <div className={faqOpen === 2 ? "landing-faq-content show" : "landing-faq-content"}>
                        <b>Yes</b>, all your previous timestamps and data from Tubestamp will be available on Bumpups. We have ensured a smooth transition so that you do not lose any of your valuable data.
                    </div>
                </div>

                <div className="landing-faq-item" onClick={() => toggleFaq(3)}>
                    <h3>Are there any new features on Bumpups?</h3>
                    <div className={faqOpen === 3 ? "landing-faq-content show" : "landing-faq-content"}>
                        <b>Yes</b>, Bumpups introduces several new features designed to enhance your content creation process. In addition to an improved user experience, we now offer automated video descriptions, tags, and much more to streamline your workflow and optimize your video's reach.
                    </div>
                </div>
            </div>

            <CookieBar /> 
            <Footer />
        </AuthProvider>
    );
}

export default TubestampLanding;