




// Initialize an empty array to hold unsubscribe functions for Firestore listeners
let listeners = [];

// Function to register a new Firestore listener
export const registerListener = (unsubscribeFunc) => {
  // Add the unsubscribe function to the listeners array
  listeners.push(unsubscribeFunc);
};

// Function to unregister all Firestore listeners
export const unregisterAllListeners = () => {
  // Loop through the listeners array and call each unsubscribe function
  listeners.forEach((unsubscribe) => unsubscribe());
  
  // Empty the listeners array
  listeners = [];
};
