import React, { useState, useEffect } from 'react';
import './App.css';
import HomePage from './views/HomePage';
import TubestampLanding from './tubelandingPage/tubestampLanding';
import PricingPage from './views/PricingPage';
import GeneralDashboard from './myDashboard/generalDashboard';
import Dashboard from './myCreator/Dashboard';
import NonCreatorDashboard from './myNonCreator/nonCreatorDashboard';
import Navbar from './components/Navbar';
import { AuthProvider } from './AuthContext';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import SignUpPage from './views/SignUpPage';
import SignInPage from './views/SignInPage';
import Aboutus from './views/Aboutus';
import UserSettings from './settings/UserSettings';
import SubscriptionPage from './myPlans/SubscriptionPage';
import TermsOfService from './miscPages/TermsOfService';
import PrivacyPolicy from './miscPages/PrivacyPolicy';
import "@stripe/stripe-js"

function App() {

   // Read the initial state from localStorage, or default to true
   const [isSidebarOpen, setIsSidebarOpen] = useState(
    localStorage.getItem('isSidebarOpen') === 'true'
  );

  useEffect(() => {
    // Save to localStorage whenever isSidebarOpen changes
    localStorage.setItem('isSidebarOpen', isSidebarOpen);
  }, [isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(prevIsSidebarOpen => {
      // Calculate the new state value and save it to localStorage
      const newState = !prevIsSidebarOpen;
      localStorage.setItem('isSidebarOpen', newState);
      return newState; // Return the new state value
    });
  };

  return (
    <AuthProvider>
      <Router>
        <div className="App">
        <Navbar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <Routes>
            <Route path="/" element={<TubestampLanding />} />
            {/* <Route path="/pricing" element={<PricingPage />} />
            <Route path="/aboutus" element={<Aboutus />} />
            <Route path="/dashboard" element={<PrivateRoute><GeneralDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/creator" element={<PrivateRoute><Dashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/plans" element={<PrivateRoute>< SubscriptionPage isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/settings/*" element={<PrivateRoute><UserSettings isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/links" element={<PrivateRoute><NonCreatorDashboard isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /></PrivateRoute>} />

            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/signin" element={<SignInPage />} /> */}

{/* 
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} /> */}
            <Route path="*" element={<Navigate to="/" replace />} />

            {/* <Route path="/processing" element={<ProcessingPage />} /> New Route */}

          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
